import * as React from 'react';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { didomiScript } from './ConsentScript';
import { Kasda } from '../../Kasda/Kasda';

type TYPE = 'URL' | 'code';
export const TYPE_OPTIONS: {URL: TYPE; CODE: TYPE } = {
  URL: 'URL',
  CODE: 'code',
};

type LOADING_TYPE = 'defer' | 'async' | 'blocking';
export const LOADING_TYPE_OPTIONS: {DEFER: LOADING_TYPE; ASYNC: LOADING_TYPE; BLOCKING: LOADING_TYPE} = {
  DEFER: 'defer',
  ASYNC: 'async',
  BLOCKING: 'blocking',
};


type Script = {
  attributes: object | null;
  loadingType: LOADING_TYPE;
  name: string;
  source: string;
  type: TYPE;
};

type ExternalScriptsProps = {
  scripts: Array<Script>;
  consentProvider: string;
};

const didomiScriptObject = {
  name: 'Didomi',
  attributes: null,
  loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
  source: didomiScript,
  type: TYPE_OPTIONS.CODE,
};

// const oneTrustScriptAutoBlock = {
//   type: TYPE_OPTIONS.URL,
//   source: 'https://cdn.cookielaw.org/consent/01927713-1789-77c5-a126-42e92c045d10/OtAutoBlock.js',
//   name: 'OneTrustAutoBlock',
//   attributes: null,
//   loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
// };

// const oneTrustScriptURL = {
//   type: TYPE_OPTIONS.URL,
//   source: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
//   name: 'OneTrustURL',
//   attributes: {
//     'data-domain-script': '01927713-1789-77c5-a126-42e92c045d10',
//   },
//   loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
// };

// const oneTrustScriptIAB = {
//   type: TYPE_OPTIONS.URL,
//   source: 'https://cdn.cookielaw.org/opt-out/otCCPAiab.js',
//   name: 'OneTrustIAB',
//   attributes: null,
//   loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
// };

// const oneTrustScriptInit = {
//   type: TYPE_OPTIONS.CODE,
//   source: 'function OptanonWrapper() { }',
//   name: 'OneTrustInit',
//   attributes: null,
//   loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
// };

const trustArcScriptCookieBlockCore = {
  type: TYPE_OPTIONS.URL,
  source: 'https://consent.trustarc.com/autoblockasset/core.min.js?domain=trustarcdemo3.com',
  name: 'TrustArcCookieBlockCore',
  attributes: null,
  loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
};

const trustArcScriptCookieBlockOptOut = {
  type: TYPE_OPTIONS.URL,
  source: 'https://consent.trustarc.com/autoblockoptout?domain=trustarcdemo3.com',
  name: 'TrustArcCookieBlockOptOut',
  attributes: null,
  loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
};

const trustArcScriptInit = {
  type: TYPE_OPTIONS.URL,
  source: 'https://consent.trustarc.com/notice?domain=trustarcdemo3.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1',
  name: 'TrustArcInit',
  attributes: null,
  loadingType: LOADING_TYPE_OPTIONS.BLOCKING,
};

function createScriptTag({ type, source, name, attributes = {}, loadingType }: Script) {
  if (type === TYPE_OPTIONS.URL && source.length > 0) {
    if (loadingType === LOADING_TYPE_OPTIONS.DEFER) {
      return <script defer src={source} type="text/javascript" key={name} {...attributes} />;
    } if (loadingType === LOADING_TYPE_OPTIONS.ASYNC) {
      return <script async src={source} type="text/javascript" key={name} {...attributes} />;
    }
    return <script src={source} type="text/javascript" key={name} {...attributes} />;
  }

  if (type === TYPE_OPTIONS.CODE && source.length > 0) {
    return (
      <script type="text/javascript" key={name} {...attributes}>{source}</script>
    );
  }

  return null;
}

const removeLegacyOneTrustScripts = (scripts: Array<Script>) => {
  return scripts.filter(script => !script.name.toLowerCase().includes('onetrust'));
};

export const ExternalScriptsComponent = ({ scripts, consentProvider }: ExternalScriptsProps) => {
  const filteredScripts = removeLegacyOneTrustScripts(scripts);

  let consentScripts = [didomiScriptObject];

  // if (consentProvider === 'onetrust') {
  //   consentScripts = [oneTrustScriptAutoBlock, oneTrustScriptURL, oneTrustScriptIAB, oneTrustScriptInit];
  // }

  if (consentProvider === 'trustarc') {
    consentScripts = [trustArcScriptCookieBlockCore, trustArcScriptCookieBlockOptOut, trustArcScriptInit];
  }

  const finalScripts = [...consentScripts, ...filteredScripts];

  return (
    <Kasda.Script>
      {finalScripts.map(createScriptTag)}
    </Kasda.Script>
  );
};

export const ExternalScripts = (props: ExternalScriptsProps) => (
  <ErrorBoundary>
    <ExternalScriptsComponent {...props} />
  </ErrorBoundary>
);
